import React                   from 'react';
import Breakpoint              from 'react-socks';
import { Container, Col, Row } from 'react-grid-system';
import styled                  from '@emotion/styled';
import { graphql }             from 'gatsby';

import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Boxgroup          from '@interness/theme-twentynineteen/src/components/Boxgroup/Boxgroup';
import Display           from '@interness/theme-twentynineteen/src/components/Display/Display';
import CTASection        from '@interness/web-core/src/components/modules/CTASection/CTASection';
import RandomBrandHeaderImages
                         from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Mounted           from '@interness/web-core/src/components/structure/Mounted/Mounted';

const List = styled.ul`
  margin-left: 0;

  li {
    margin: 0;
  }
`;


const IndexPage = ({ data }) => {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Mounted>
        <Breakpoint large up>
          <Boxgroup/>
          <Spacer/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Reich</Heading>
          <Spacer height={20}/>
          <span style={{ textAlign: 'justify' }}>
            <p>Willkommen bei Juwelier Reich, Ihrem exklusiven Fachgeschäft für Uhren, Schmuck und Trauringe im Herzen von Altenburg. Als anerkannte Meisterwerkstatt und mit unserer Expertise als Uhrmachermeister stehen wir für handwerkliche Perfektion und außergewöhnliche Kreationen. Unsere Leidenschaft gilt dem Schmuck in all seinen Facetten – von klassischen Eheringen bis hin zu einzigartigen Antragsringen.</p>
            <p>In unserer Meisterwerkstatt fertigen und reparieren wir Uhren mit größter Sorgfalt und Präzision. Wir verstehen unser Handwerk nicht nur als Beruf, sondern als Berufung und bringen diese Hingabe in jedes einzelne Stück ein, das unsere Werkstatt verlässt. Unsere Kunden schätzen diesen persönlichen Service, der Juwelier Reich zu einem Namen gemacht hat, dem man vertraut.</p>
            <p>In unserem Juweliergeschäft steht Ihre Zufriedenheit an erster Stelle. Wir nehmen uns ausgiebig Zeit, um auf Ihre individuellen Wünsche und Vorstellungen einzugehen, sei es in Bezug auf Schmuckstücke, Uhren oder die perfekten Trauringe für Ihren besonderen Tag. Bei Juwelier Reich genießen Sie eine moderne und zugleich behagliche Atmosphäre, in der Sie sich entspannt zurücklehnen und Ihre Auswahl treffen können.</p>
            <p>Wir sind stolz darauf, ein inhabergeführtes Unternehmen zu sein, das sich durch Authentizität und Engagement auszeichnet. Marcel Aretz und sein engagiertes Team freuen sich darauf, Sie in Altenburg willkommen zu heißen und Sie auf Ihrer Reise zu exquisiten Schätzen zu begleiten.</p>
            <p>Besuchen Sie uns bald bei Juwelier Reich in Altenburg und erleben Sie eine Welt voller Schönheit und Eleganz. Wir freuen uns darauf, Sie bei uns zu begrüßen und gemeinsam mit Ihnen Schmuckstücke zu finden, die nicht nur begeistern, sondern auch Geschichten erzählen.</p>
            <p>BIS BALD IN ALTENBURG</p>
            <p>MARCEL ARETZ UND SEIN TEAM</p>
          </span>
        </section>
        <Spacer height={20}/>
        <section>
          <Heading tag={'h2'}>Aktuelle Kollektionen</Heading>
          <Spacer height={20}/>
          <Display/>
        </section>
        <Spacer height={20}/>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <p>
            Professioneller Service gehört seit jeher zu den wesentlichen Tugenden unseres Hauses. Wir reparieren Ihre
            Armbanduhren, Ihre Großuhren, führen Goldschmiedereparaturen aus und kaufen Ihr Altgold an.
          </p>
          <MoreExpander>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h2>Armbanduhren</h2>
                  <List>
                    <li>mechanische Uhren / Handaufzug</li>
                    <li>Quarzarmbanduhren</li>
                    <li>Automatikuhren</li>
                    <li>Batteriewechsel</li>
                    <li>Komplette Revisionen</li>
                    <li>Taschenuhren</li>
                    <li>Glasersatz</li>
                    <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                    <li>Bandanpassung</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h2>Goldschmiedereparaturen</h2>
                  <List>
                    <li>Goldschmiedereparaturen aller Art</li>
                    <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                    <li>Ringgrößen ändern</li>
                    <li>Perlenketten neu aufziehen oder knoten</li>
                    <li>Trauringumarbeitung</li>
                    <li>Schmuck reinigen und aufarbeiten</li>
                    <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                  </List>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h2>Großuhren</h2>
                  <List>
                    <li>Heim – und Tischuhren, Jahresuhren</li>
                    <li>Standuhren</li>
                    <li>Regulatoren</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h2>Altgold- und Altsilberankauf</h2>
                  <List>
                    <li>Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an:</li>
                    <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken und Münzen</li>
                    <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen</li>
                    <li>Altgoldankauf ist Vertrauenssache</li>
                  </List>
                </Col>
              </Row>
            </Container>
          </MoreExpander>
        </section>
        <Spacer/>
      </Wrapper>
      <CTASection/>
    </>
  )
};

export const query = graphql`
    query {
        trauringImage: directusMediaCollection(name: {eq: "index-trauringe"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 233, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default IndexPage;
